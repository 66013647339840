import { defineRecipe } from '@chakra-ui/react';

const primary = {
    color: 'gray.50',
    bg: 'brandPink.400',
    borderColor: 'brandPink.400',
    focusRingColor: 'brandPink.400',
    _hover: {
        bg: 'brandPink.200',
    },
    _pressed: {
        bg: 'brandPink.600',
    },
    _disabled: {
        bg: 'gray.200',
        borderColor: 'gray.200',
        color: 'gray.400',
    },
};

const secondary = {
    color: 'gray.900',
    borderColor: 'gray.900',
    focusRingColor: 'gray.900',
    bg: 'white',
    _hover: {
        bg: 'gray.100',
    },
    _pressed: {
        bg: 'gray.300',
    },
    _disabled: {
        bg: 'gray.200',
        borderColor: 'gray.200',
        color: 'gray.400',
    },
};

const tab = {
    bg: 'tabs.default.bg',
    color: 'tabs.default.fg',
};
const tabFocused = {
    bg: 'tabs.focused.bg',
    color: 'tabs.focused.fg',
};

const destructive = {
    color: 'red.500',
    borderColor: 'red.500',
    focusRingColor: 'red.500',
    _hover: {
        bg: 'red.50',
    },
    _pressed: {
        bg: 'red.100',
    },
    _disabled: {
        bg: 'gray.200',
        borderColor: 'gray.200',
        color: 'gray.400',
    },
};

export const buttonRecipe = defineRecipe({
    base: {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        verticalAlign: 'middle',
        userSelect: 'none',
        cursor: 'pointer',
        position: 'relative',
        whiteSpace: 'nowrap',
        transitionProperty: 'backgrounds',
        transitionDuration: 'moderate',
        fontWeight: 'medium',
        borderWidth: '1px',
        borderStyle: 'solid',
        focusRingColor: 'gray.900',
    },
    variants: {
        variant: {
            primary,
            secondary,
            destructive,
            tab,
            tabFocused,
            // backwards compatibility
            solid: {
                ...secondary,
                bg: secondary._pressed.bg,
                borderColor: secondary._pressed.bg,
                _hover: { bg: 'gray.200' },
            },
            outline: secondary,
            subtle: { ...secondary, bg: secondary._hover.bg, borderColor: secondary._hover.bg },
            plain: {
                borderColor: 'transparent',
                bg: 'none',
                _hover: {
                    ...secondary._hover,
                    borderColor: 'transparent',
                    bg: 'none',
                },
                _disabled: {
                    ...secondary._hover,
                    borderColor: 'transparent',
                    bg: 'none',
                },
            },
            ghost: {
                ...secondary,
                borderColor: 'transparent',
                bg: 'none',
                _hover: {
                    ...secondary._hover,
                    borderColor: 'transparent',
                },
            },
        },
        spacing: {
            slim: {
                height: 7,
            },
        },
        size: {
            '2xs': {},
            xs: {},
            sm: {
                rounded: 'sm',
                lineHeight: '1.25em',
                height: '9',
                minWidth: '9',
                paddingInline: '3.5',
                fontSize: 'sm',
                gap: '2',
            },
            md: {},
            lg: {},
            xl: {},
            '2xl': {},
        },
    },
    defaultVariants: {
        variant: 'secondary',
        size: 'sm',
    },
});
