import { defineSlotRecipe } from '@chakra-ui/react';

type Side = 'n' | 's' | 'e' | 'w' | 'ne' | 'nw' | 'se' | 'sw';
type BorderConfig = { side: Side | Side[]; size: number; color?: string; inset?: boolean };
type BoxShadowBorderType = (
    sides: BorderConfig[],
    getMakeSubStyle?: (border: BoxShadowBorderType) => object,
    _startingBoxShadows?: string[]
) => object;

const resetCellBorders = {
    // Need an !important here to force override Chakra's default without impacting
    // the base styles for other variants. Otherwise, we'd need to apply the reset
    // to the base, which would reset stuff for variants we don't intend to reset
    border: 'none !important',
};

const boxShadowBorder: BoxShadowBorderType = (sides, getMakeSubStyle, _startingBoxShadows = []) => {
    const makeBoxShadow = (sides: BorderConfig[]) => {
        return sides.flatMap(({ side: sides, size, inset, color = 'var(--chakra-colors-gray-300)' }) => {
            sides = sides instanceof Array ? sides : [sides];

            const insetFlip = inset ? -1 : 1;
            return sides.map((side) => {
                const x = (side.includes('w') ? -size : side.includes('e') ? size : 0) * insetFlip;
                const y = (side.includes('n') ? -size : side.includes('s') ? size : 0) * insetFlip;
                return `${inset ? 'inset ' : ''} ${x}px ${y}px ${color}`;
            });
        });
    };
    const boxShadow = [...makeBoxShadow(sides), ...(_startingBoxShadows ?? [])];

    const makeSubStyle: BoxShadowBorderType = (sides, makeSubStyle) => boxShadowBorder(sides, makeSubStyle, boxShadow);
    const subStyles = getMakeSubStyle?.(makeSubStyle);

    return { boxShadow: boxShadow.join(','), ...(subStyles ?? {}) };
};

const rowHoverColor = 'color-mix(in hsl, white, var(--chakra-colors-gray-900) 5%)';

const header = {
    bg: 'white',
    '& > tr': {
        zIndex: 2,
        '&:hover': {
            // disable row hover inside header because cells are colored instead
            bg: 'white !important',
        },
    },
};
const body = {};
const footer = {};
const row = {
    ...boxShadowBorder([], (border) => ({
        _notFirst: { side: ['n'], size: 1 },
        _notLast: { side: ['s'], size: 1 },
    })),
    bg: 'white',
    '&:not(.selected)': {
        '& > td': {
            bg: 'white',
        },
        _even: {
            '& > td': {
                bg: 'gray.50',
            },
        },
        _hover: {
            '& > td': {
                bg: 'gray.100',
            },
        },
    },
    '&.selected > td': {
        bg: 'gray.200',
    },
};
const columnHeader = {
    ...resetCellBorders,
    position: 'relative',
    paddingX: 3,
    paddingY: 2,
    bg: 'white',
    _hover: {
        bg: rowHoverColor,
    },

    // user select messes with col resize and there probably isn't any use
    // for highlighting column headers.
    userSelect: 'none',

    ...boxShadowBorder([
        { side: ['nw', 'ne', 'sw', 'se'], size: 1 },
        { side: 's', size: 1, inset: true },
    ]),
};

const cell = {
    ...resetCellBorders,
    position: 'relative',
    paddingX: 3,
    paddingY: 1,
    ...boxShadowBorder([{ side: ['nw', 'ne', 'sw', 'se'], size: 1 }]),
};
const root = {
    borderCollapse: 'separate',
    borderSpacing: '1px',

    // Since the top of the table has an exposed background-border and it combines
    // with the top row border, lets cover it
    ...boxShadowBorder([{ side: 'n', size: 1, color: 'white', inset: true }]),

    '& th.resizing, & td.resizing': {
        '&:after': {
            content: '""',
            position: 'absolute',
            top: '-2px',
            bottom: '-2px',
            right: '-1px',
            width: '2px',
            bg: 'gray.900',
            zIndex: 1,
            ...boxShadowBorder([
                { side: ['sw', 'se', 'nw', 'ne'], size: 1, color: 'var(--chakra-colors-gray-900)', inset: true },
            ]),
        },
    },
};

const makeRoundedCellStyles = (size: string) => {
    return {
        [size]: {
            root: {
                '& thead tr': {
                    _first: {
                        '& th': {
                            _first: {
                                borderTopLeftRadius: size,
                            },
                            _last: {
                                borderTopRightRadius: size,
                            },
                        },
                    },
                },
                '& tbody tr': {
                    _last: {
                        '& td': {
                            _first: {
                                borderBottomLeftRadius: size,
                            },
                            _last: {
                                borderBottomRightRadius: size,
                            },
                        },
                    },
                },
            },
        },
    };
};

export const tableSlotRecipe = defineSlotRecipe({
    slots: ['root', 'header', 'body', 'footer', 'row', 'columnHeader', 'cell'],
    base: {
        root: {},
        footer: {},
        row: {},
        columnHeader: {},
        cell: {},
    },
    variants: {
        variant: {
            bgBorder: {
                root,
                header,
                body,
                footer,
                row,
                columnHeader,
                cell,
            },
            line: {
                root: {
                    borderRadius: 0,
                    borderCollapse: 'collapse',
                    borderColor: 'gray.300',
                },
                row: {
                    borderColor: 'gray.300',
                },
                columnHeader: {
                    borderColor: 'gray.300',
                },
                cell: {
                    borderColor: 'gray.300',
                },
            },
        },
        cellRounding: {
            ...makeRoundedCellStyles('2xs'),
            ...makeRoundedCellStyles('xs'),
            ...makeRoundedCellStyles('sm'),
            ...makeRoundedCellStyles('md'),
            ...makeRoundedCellStyles('lg'),
            ...makeRoundedCellStyles('xl'),
            ...makeRoundedCellStyles('2xl'),
        },
    },
    defaultVariants: {
        variant: 'bgBorder',
    },
});
