'use client';

import { ConnectEnv, getEnv } from '@app/_lib/env';
import { useSDK } from '@connect-core/connect-sdk';
import config from '@root/config';
import Script from 'next/script';
import { useEffect, useMemo, useState } from 'react';

export default function Datadog() {
    //if not running on localhost load datadog
    const isLocalhost = typeof window !== 'undefined' && window.location.hostname === 'localhost';

    const { users } = useSDK();
    const { data, isLoading } = users.useUser();

    const [userId, orgId] = useMemo(() => {
        if (isLoading) {
            return ['', ''];
        }

        return [data?.id, data?.organization?.id];
    }, [data?.id, data?.organization?.id, isLoading]);

    const [env, setEnv] = useState<ConnectEnv>();

    // We only want this hook to run once.
    useEffect(() => {
        const fetchEnv = async () => {
            const envData = await getEnv();
            setEnv(envData);
        };

        if (!isLocalhost) {
            fetchEnv();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isLocalhost || !env?.datadog?.clientToken || !env?.datadog?.applicationId) {
        return <></>;
    }

    const appVersion = config.datadog.version;

    return (
        <>
            <Script id="datadog-rum">
                {`
                (function(h,o,u,n,d) {
                h=h[d]=h[d]||{q:[],onReady:function(c){h.q.push(c)}}
                d=o.createElement(u);d.async=1;d.src=n
                n=o.getElementsByTagName(u)[0];n.parentNode.insertBefore(d,n)
                })(window,document,'script','https://www.datadoghq-browser-agent.com/us1/v5/datadog-rum.js','DD_RUM')
                window.DD_RUM.onReady(function() {
                    window.DD_RUM.setGlobalContext({
                        'user.id': '${userId}',
                        'organization.id': '${orgId}'
                    });
                    window.DD_RUM.init({
                        clientToken: '${env.datadog.clientToken}',
                        applicationId: '${env.datadog.applicationId}',
                        site: 'us5.datadoghq.com',
                        service: 'web',
                        env: '${env.datadog.env}',
                        // Specify a version number to identify the deployed version of your application in Datadog
                        version: '${appVersion}',
                        sessionSampleRate: 100,
                        sessionReplaySampleRate: 20,
                        trackUserInteractions: true,
                        trackResources: true,
                        trackLongTasks: true,
                        defaultPrivacyLevel: 'mask-user-input',
                    });
                })
            `}
            </Script>

            {/* Datadog Logs Script */}
            <Script id="datadog-logs">
                {`
                    (function(h,o,u,n,d) {
                    h=h[d]=h[d]||{q:[],onReady:function(c){h.q.push(c)}}
                    d=o.createElement(u);d.async=1;d.src=n
                    n=o.getElementsByTagName(u)[0];n.parentNode.insertBefore(d,n)
                    })(window,document,'script','https://www.datadoghq-browser-agent.com/us1/v5/datadog-logs.js','DD_LOGS')
                    window.DD_LOGS.onReady(function() {
                        window.DD_LOGS.setGlobalContext({
                            'user.id': '${userId}',
                            'organization.id': '${orgId}'
                        });
                        window.DD_LOGS.init({
                            clientToken: '${env.datadog.clientToken}',
                            site: 'us5.datadoghq.com',
                            service: 'web',
                            env: '${env.datadog.env}',
                            // Specify a version number to identify the deployed version of your application in Datadog
                            version: '${appVersion}',
                            forwardErrorsToLogs: true,
                            forwardConsoleLogs: ['error'],
                            sessionSampleRate: 100
                        });
                    })
                `}
            </Script>
        </>
    );
}
