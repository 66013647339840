import { createSystem, defaultConfig, defineConfig, defineSlotRecipe, SystemConfig } from '@chakra-ui/react';
import { buttonRecipe, tableSlotRecipe } from './recipes';

// TODO: These exist for backwards-compatibility and need removed to fully migrate to
// the GOODS redesign colors
import * as old from './old';

const brandPink = {
    50: { value: '#FAE1EE' },
    100: { value: '#F2B5D5' },
    200: { value: '#EC83B9' },
    300: { value: '#E84C9D' },
    400: { value: '#E50086' },
    500: { value: '#E4006D' },
    600: { value: '#D3006A' },
    700: { value: '#BC0064' },
    800: { value: '#A70060' },
    900: { value: '#800057' },
};

const gray = {
    50: { value: '#FAFAFA' },
    100: { value: '#F4F4F5' },
    200: { value: '#E4E4E7' },
    300: { value: '#D4D4D8' },
    400: { value: '#A1A1AA' },
    500: { value: '#71717A' },
    600: { value: '#52525B' },
    700: { value: '#3F3F46' },
    800: { value: '#27272A' },
    900: { value: '#18181B' },
};

const red = {
    50: { value: '#FFEBEE' },
    100: { value: '#FFCDD2' },
    200: { value: '#EF9A9A' },
    300: { value: '#E57373' },
    400: { value: '#EF5350' },
    500: { value: '#E35141' },
    600: { value: '#E53935' },
    700: { value: '#D32F2F' },
    800: { value: '#C62828' },
    900: { value: '#B71C1C' },
};

const blue = {
    50: { value: '#E3F2FD' },
    100: { value: '#BBDEFB' },
    200: { value: '#90CAF9' },
    300: { value: '#64B5F6' },
    400: { value: '#42A5F5' },
    500: { value: '#2196F3' },
    600: { value: '#1E88E5' },
    700: { value: '#1976D2' },
    800: { value: '#1565C0' },
    900: { value: '#0D47A1' },
};

const green = {
    50: { value: '#E8F5E9' },
    100: { value: '#C8E6C9' },
    200: { value: '#A5D6A7' },
    300: { value: '#81C784' },
    400: { value: '#66BB6A' },
    500: { value: '#4CAF50' },
    600: { value: '#43A047' },
    700: { value: '#388E3C' },
    800: { value: '#2E7D32' },
    900: { value: '#1B5E20' },
};

const amber = {
    50: { value: '#FFF8E1' },
    100: { value: '#FFECB3' },
    200: { value: '#FFE082' },
    300: { value: '#FFD54F' },
    400: { value: '#FFCA28' },
    500: { value: '#FFC107' },
    600: { value: '#FFB300' },
    700: { value: '#FFA000' },
    800: { value: '#FF8F00' },
    900: { value: '#FF6F00' },
};

const deepPurple = {
    50: { value: '#EDE7F6' },
    100: { value: '#D1C4E9' },
    200: { value: '#B39DDB' },
    300: { value: '#9575CD' },
    400: { value: '#7E57C2' },
};

const purple = {
    50: { value: '#F3E5F5' },
    100: { value: '#E1BEE7' },
    200: { value: '#CE93D8' },
    300: { value: '#BA68C8' },
    400: { value: '#AB47BC' },
    500: { value: '#9C27B0' },
    600: { value: '#8E24AA' },
    700: { value: '#7B1FA2' },
    800: { value: '#6A1B9A' },
    900: { value: '#4A148C' },
};

const lightBlue = {
    50: { value: '#E1F5FE' },
    100: { value: '#B3E5FC' },
    200: { value: '#81D4FA' },
    300: { value: '#4FC3F7' },
    400: { value: '#29B6F6' },
    500: { value: '#03A9F4' },
    600: { value: '#039BE5' },
    700: { value: '#0288D1' },
    800: { value: '#0277BD' },
    900: { value: '#01579B' },
};

const teal = {
    50: { value: '#E0F2F1' },
    100: { value: '#B2DFDB' },
    200: { value: '#80CBC4' },
    300: { value: '#4DB6AC' },
    400: { value: '#26A69A' },
    500: { value: '#009688' },
    600: { value: '#00897B' },
    700: { value: '#00796B' },
    800: { value: '#00695C' },
    900: { value: '#004D40' },
};

const lime = {
    50: { value: '#F9FBE7' },
    100: { value: '#F0F4C3' },
    200: { value: '#E6EE9C' },
    300: { value: '#DCE775' },
    400: { value: '#D4E157' },
    500: { value: '#CDDC39' },
    600: { value: '#C0CA33' },
    700: { value: '#AFB42B' },
    800: { value: '#9E9D24' },
    900: { value: '#827717' },
};

const indigo = {
    50: { value: '#E8EAF6' },
    100: { value: '#C5CAE9' },
    200: { value: '#9FA8DA' },
    300: { value: '#7986CB' },
    400: { value: '#5C6BC0' },
    500: { value: '#3F51B5' },
    600: { value: '#3949AB' },
    700: { value: '#303F9F' },
    800: { value: '#283593' },
    900: { value: '#1A237E' },
};

const cyan = {
    50: { value: '#E0F7FA' },
    100: { value: '#B2EBF2' },
    200: { value: '#80DEEA' },
    300: { value: '#4DD0E1' },
    400: { value: '#26C6DA' },
    500: { value: '#00BCD4' },
    600: { value: '#00ACC1' },
    700: { value: '#0097A7' },
    800: { value: '#00838F' },
    900: { value: '#006064' },
};

const lightGreen = {
    50: { value: '#F1F8E9' },
    100: { value: '#DCEDC8' },
    200: { value: '#C5E1A5' },
    300: { value: '#AED581' },
    400: { value: '#9CCC65' },
    500: { value: '#8BC34A' },
    600: { value: '#7CB342' },
    700: { value: '#689F38' },
    800: { value: '#558B2F' },
    900: { value: '#33691E' },
};

const deepOrange = {
    50: { value: '#FBE9E7' },
    100: { value: '#FFCCBC' },
    200: { value: '#FFAB91' },
    300: { value: '#FF8A65' },
    400: { value: '#FF7043' },
    500: { value: '#FF5722' },
    600: { value: '#F4511E' },
    700: { value: '#E64A19' },
    800: { value: '#D84315' },
    900: { value: '#BF360C' },
};

const orange = {
    50: { value: '#FFF3E0' },
    100: { value: '#FFE0B2' },
    200: { value: '#FFCC80' },
    300: { value: '#FFB74D' },
    400: { value: '#FFA726' },
};

const makeHierarchyPalette = (baseColor: string) => {
    return {
        solid: { value: `{colors.${baseColor}.200}` },
        contrast: { value: '{colors.gray.700}' },
        fg: { value: '{colors.gray.700}' },
        muted: { value: `{colors.${baseColor}.200}` },
        subtle: { value: `{colors.${baseColor}.200}` },
        emphasized: { value: `{colors.${baseColor}.300}` },
        focusRing: { value: `{colors.${baseColor}.200}` },
    };
};

const makeTagPalette = (baseColor: string) => {
    return {
        solid: { value: `{colors.${baseColor}.100}` },
        contrast: { value: `{colors.${baseColor}.800}` },
        fg: { value: `{colors.${baseColor}.800}` },
        muted: { value: `{colors.${baseColor}.100}` },
        subtle: { value: `{colors.${baseColor}.200}` },
        emphasized: { value: `{colors.${baseColor}.100}` },
        focusRing: { value: `{colors.${baseColor}.100}` },
    };
};

// TODO: implement Rotating colors

const customTheme: SystemConfig = defineConfig({
    cssVarsPrefix: 'chakra',
    globalCss: {
        body: {
            height: '100%',
            color: 'fg.default',
            colorPalette: 'brand',
            bg: 'bg.canvas',
        },
        ':root': {
            '--nav-height': '67px',
            '--sidebar-width': '192px',
            '--sidebar-width-not-expanded': '56px',
            '--tabs-list-height': '42px',
            '--tabs-panel-pl': '2rem',
            '--tabs-panel-pr': '2rem',
            '--tabs-panel-pt': '1.25rem',
            '--tabs-panel-pb': '1.25rem',
            '--info-card-height': '160px',
            '--table-footer-height': '64px',
            '--modal-body-pt': '0.5rem',
            '--modal-body-pb': '0.5rem',
            '--modal-footer-height': '64px',
            '--input-border': '2px solid var(--Colors-Gray-200, #718096)',
        },
        '*::placeholder': {
            opacity: 1,
            color: 'fg.muted',
        },
        '*, *::before, &::after': {
            borderColor: 'border.default',
        },
        html: {
            color: 'fg',
            bg: 'bg',
            height: '100%',
            colorPalette: 'brand',
        },
        '#__next, #root': {
            display: 'flex',
            flexDirection: 'column',
            minH: '100%',
        },
    },
    theme: {
        tokens: {
            colors: {
                brandPink,
                gray,
                red,
                blue,
                green,
                amber,
                brand: gray,

                deepPurple,
                purple,
                lightBlue,
                teal,
                lime,
                indigo,
                cyan,
                lightGreen,
                deepOrange,
                orange,

                item: teal,
                each: lightGreen,
                pack: lime,
                case: orange,
                mixedModule: deepPurple,
                pallet: purple,
                shipper: indigo,
                truckload: brandPink,
            },
            fonts: {
                heading: { value: 'var(--font-roboto), -apple-system, system-ui, sans-serif' },
                body: { value: 'var(--font-roboto), -apple-system, system-ui, sans-serif' },
            },
            sizes: {
                11: { value: '2.75rem' },
                15: { value: '3.75rem' },
            },
            spacing: {
                4.5: { value: '1.125rem' },
            },
        },
        recipes: {
            button: buttonRecipe,
        },
        slotRecipes: {
            table: tableSlotRecipe,
            card: defineSlotRecipe({
                slots: ['root'],
                base: {
                    root: {
                        bg: 'bg.surface',
                    },
                },
            }),
            select: defineSlotRecipe({
                slots: ['root'],
                base: {
                    root: {
                        '& [data-focus-visible] + div': {
                            // Need to override a z-index set directly on this
                            // element. Because the existing z-index creates a new
                            // stacking context, we can't just set a z-index on the
                            // 'content' slot (which is a child of this element).
                            zIndex: '3 !important',
                        },
                    },
                },
            }),
        },
        textStyles: {
            xs: {
                fontSize: { value: 'xs' },
            },
            sm: {
                fontSize: { value: 'sm' },
            },
            md: {
                fontSize: { value: 'md' },
            },
            lg: {
                fontSize: { value: 'lg' },
            },
            xl: {
                fontSize: { value: 'xl' },
            },
            '2xl': {
                fontSize: { value: '2xl' },
            },
            '3xl': {
                fontSize: { value: '3xl' },
            },
            '4xl': {
                fontSize: { value: '4xl' },
                letterSpacing: { value: '-0.02em' },
            },
            '5xl': {
                fontSize: { value: '5xl' },
                letterSpacing: { value: '-0.02em' },
            },
            '6xl': {
                fontSize: { value: '6xl' },
                letterSpacing: { value: '-0.02em' },
            },
            '7xl': {
                fontSize: { value: '7xl' },
                letterSpacing: { value: '-0.02em' },
            },
        },
        semanticTokens: {
            colors: {
                // TODO: Needs removed for redesign
                ...old.colorTokens,

                panel: {
                    bg: { value: 'colors.gray.900' },
                    fg: { value: '#B2B2B5' },
                },
                tabs: {
                    bar: { value: 'colors.gray.200' },
                    focused: {
                        bg: { value: 'white' },
                        fg: { value: 'gray.900' },
                    },
                    default: {
                        bg: { value: 'transparent' },
                        fg: { value: 'colors.gray.400' },
                    },
                },
                nav: {
                    bar: {
                        bg: { value: 'colors.panel.bg' },
                        fg: { value: 'colors.panel.fg' },
                    },
                    hover: {
                        parent: { value: 'colors.whiteAlpha.300' },
                        child: { value: 'colors.whiteAlpha.300' },
                    },
                    active: {
                        parent: { value: 'colors.brandPink.900' },
                        child: { value: 'colors.brandPink.800' },
                    },
                    hinted: {
                        parent: { value: 'colors.brandPink.900' },
                        child: { value: 'colors.brandPink.900' },
                    },
                },
                topBar: {
                    bg: { value: 'colors.blackAlpha.200' },
                },
                alert: {
                    fg: {
                        success: { value: 'colors.green.800' },
                        error: { value: 'colors.red.800' },
                        warning: { value: 'colors.orange.800' },
                        info: { value: 'colors.blue.800' },
                    },
                    bg: {
                        success: { value: 'colors.green.300' },
                        error: { value: 'colors.red.300' },
                        warning: { value: 'colors.orange.300' },
                        info: { value: 'colors.blue.300' },
                    },
                },
                badge: {
                    fg: {
                        success: { value: 'colors.green.800' },
                        error: { value: 'colors.red.800' },
                        warning: { value: 'colors.orange.800' },
                        info: { value: 'colors.blue.800' },
                    },
                    bg: {
                        success: { value: 'colors.green.300' },
                        error: { value: 'colors.red.300' },
                        warning: { value: 'colors.orange.300' },
                        info: { value: 'colors.blue.300' },
                    },
                },

                brandPink: makeTagPalette('brandPink'),
                gray: makeTagPalette('gray'),
                red: makeTagPalette('red'),
                blue: makeTagPalette('blue'),
                green: makeTagPalette('green'),
                amber: makeTagPalette('amber'),
                brand: makeTagPalette('gray'),

                deepPurple: makeTagPalette('deepPurple'),
                purple: makeTagPalette('purple'),
                lightBlue: makeTagPalette('lightBlue'),
                teal: makeTagPalette('teal'),
                lime: makeTagPalette('lime'),
                indigo: makeTagPalette('indigo'),
                cyan: makeTagPalette('cyan'),
                lightGreen: makeTagPalette('lightGreen'),
                deepOrange: makeTagPalette('deepOrange'),
                orange: makeTagPalette('orange'),

                item: makeHierarchyPalette('item'),
                each: makeHierarchyPalette('each'),
                pack: makeHierarchyPalette('pack'),
                case: makeHierarchyPalette('case'),
                pallet: makeHierarchyPalette('pallet'),
                shipper: makeHierarchyPalette('shipper'),
                truckload: makeHierarchyPalette('truckload'),
                mixedModule: makeHierarchyPalette('mixedModule'),
            },
            shadows: {
                xs: {
                    default: { value: '0px 0px 1px rgba(45, 55, 72, 0.05), 0px 1px 2px rgba(45, 55, 72,  0.1)' },
                    _dark: { value: '0px 0px 1px rgba(13, 14, 20, 1), 0px 1px 2px rgba(13, 14, 20, 0.9)' },
                },
                sm: {
                    default: { value: '0px 0px 1px rgba(45, 55, 72, 0.05), 0px 2px 4px rgba(45, 55, 72,  0.1)' },
                    _dark: { value: '0px 0px 1px rgba(13, 14, 20, 1), 0px 2px 4px rgba(13, 14, 20, 0.9)' },
                },
                md: {
                    default: { value: '0px 0px 1px rgba(45, 55, 72, 0.05), 0px 4px 8px rgba(45, 55, 72,  0.1)' },
                    _dark: { value: '0px 0px 1px rgba(13, 14, 20, 1), 0px 4px 8px rgba(13, 14, 20, 0.9)' },
                },
                lg: {
                    default: { value: '0px 0px 1px rgba(45, 55, 72, 0.05), 0px 8px 16px rgba(45, 55, 72,  0.1)' },
                    _dark: { value: '0px 0px 1px rgba(13, 14, 20, 1), 0px 8px 16px rgba(13, 14, 20, 0.9)' },
                },
                xl: {
                    default: { value: '0px 0px 1px rgba(45, 55, 72, 0.05), 0px 16px 24px rgba(45, 55, 72,  0.1)' },
                    _dark: { value: '0px 0px 1px rgba(13, 14, 20, 1), 0px 16px 24px rgba(13, 14, 20, 0.9)' },
                },
                focus: {
                    default: { value: '0 0 0 4px #EDF2F7' },
                    _dark: { value: '0 0 0 4px #2D3748' },
                },
            },
        },
    },
});

export const connectSystem = createSystem(defaultConfig, customTheme);
export default connectSystem;
