import { ConfigProps } from './types/config';

const config = {
    // REQUIRED
    appName: 'ConnectAI',
    appVersion: '0.1.0',
    // REQUIRED: a short description of your app for SEO tags (can be overwritten)
    appDescription: '',
    // REQUIRED (no https://, not trialing slash at the end, just the naked domain)
    domainName: 'connectai.dev',
    colors: {
        // REQUIRED — The DaisyUI theme to use (added to the main layout.js). Leave blank for default (light & dark mode). If you any other theme than light/dark, you need to add it in config.tailwind.js in daisyui.themes.
        theme: 'light',
        // REQUIRED — This color will be reflected on the whole app outside of the document (loading bar, Chrome tabs, etc..). By default it takes the primary color from your DaisyUI theme (make sure to update your the theme name after "data-theme=")
        // OR you can just do this to use a custom color: main: "#f37055". HEX only.
        main: '#570df8',
    },
    auth: {
        nextAuth: {
            secret: process.env.NEXTAUTH_SECRET || '',
        },
        cognito: {
            region: process.env.COGNITO_REGION || 'us-west-2',
            // REQUIRED — the Cognito User Pool ID
            userPoolId: process.env.COGNITO_USER_POOL_ID || '',
            // REQUIRED — the Cognito App Client ID
            clientId: process.env.COGNITO_CLIENT_ID || '',
            endpoint: `${process.env.COGNITO_ENDPOINT || ''}/${process.env.COGNITO_CLIENT_ID || ''}`,
        },
    },
    featureFlags: {
        launchDarkly: {
            sdkKey: process.env.LAUNCHDARKLY_SDK_KEY || '',
            clientSideId: process.env.LAUNCHDARKLY_CLIENT_SIDE_ID || '',
        },
    },
    datadog: {
        clientToken: process.env.DATADOG_CLIENT_TOKEN || '',
        applicationId: process.env.DATADOG_APPLICATION_ID || '',
        env: process.env.DATADOG_ENV || '',
        version: process.env.DD_VERSION || '',
    },
} as ConfigProps;

export default config;
